<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <img
            class="logo"
            src="@/assets/images/celcomLogo/logo-checkout-01.svg"
            alt="Celcom Logo"
          />

          <div class="clearfix"></div>

          <b-card-title title-tag="h2"> Restaurar mi contraseña </b-card-title>
          <b-card-text class="mb-2 "  align="center">
            Ingresa tu usuario para recibir un enlace vía email con el que
            podrás cambiar tu clave
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group label-for="forgot-password-email" >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="Email"
                    
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block class="margen-top" @click="HandleForgotPassword">
                Enviar
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'perfil-usuario' }">
              <feather-icon icon="ChevronLeftIcon" /> Volver
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async HandleForgotPassword(){

      const token = localStorage.getItem('token')

      var data = JSON.stringify({
        "email": this.userEmail
      });

      var config = {
        method: 'post',
        url: `${backendUrl}/usuarios/forgetpassword/`,
        headers: { 
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json'
        },
        data : data
      };

      try {
        await axios(config)
      } catch (error) {
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "¡El código se ha enviado exitosamente!",
              text: "Revisa tu bandeja de correo",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "No se ha podido enviar el código",
              text: "Intente nuevamente",
              icon: "CrossIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.logo {
  display: block;
  width: 72%;
  margin: auto;
  margin-top: 12%;
  margin-bottom: 32%;
}

.clearfix {
  clear: both;
  float: none;
}

h2 {
  display: block;
  text-align: center !important;
  /* font-family: Nunito Sans !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  color: $dark !important;
}

.margen-top{
  margin-top: 36%;
}
</style>
